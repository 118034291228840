import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import routes from "./pages/router";
import { WagmiProvider, createConfig, http } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { bsc, bscTestnet } from "wagmi/chains";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ConnectKitButton } from "connectkit";
import Home01 from "./pages/Home01";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import ScrollToTop from "./ScrollToTop";

export const config = createConfig({
  chains: [bsc],
  transports: {
    [bsc.id]: http("https://bsc-dataseed1.binance.org/"),
  },
});

function App() {
  const conf = createConfig(
    getDefaultConfig({
      // Required API Keys
      alchemyId: "VCw2wBRgBXca72aRhff4KRGQxnbdqANf", // or infuraId
      walletConnectProjectId: "0370e65a1c5b7c6c40d3b7a9a60a118d",

      // Required
      appName: "Your App Name",
      transports: {
        [bsc.id]: http("https://bsc-dataseed1.binance.org/"),
      },
      // Optional
      chains: [bsc],
    })
  );

  const queryClient = new QueryClient();

  const Web3Provider = ({ children }) => {
    return (
      <BrowserRouter>
        <WagmiProvider config={conf}>
          <QueryClientProvider client={queryClient}>
            <ConnectKitProvider>{children}</ConnectKitProvider>
          </QueryClientProvider>
        </WagmiProvider>
      </BrowserRouter>
    );
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        style={{ fontSize: "14px", zIndex: "10000" }}
      />
      <Web3Provider>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route exact={true} path={"/"} element={<Home01 />} />
        </Routes>
        <Footer />
      </Web3Provider>
    </>
  );
}

export default App;
