import React, { useRef, useState, useEffect } from "react";
import TopBar from "./TopBar";
import { Link, useLocation } from "react-router-dom";

import menus from "../../pages/menu";
import DarkMode from "./DarkMode";

import icon from "../../assets/images/icon/connect-wallet.svg";
import { ConnectKitButton } from "connectkit";

const Header = () => {
  const { pathname } = useLocation();
  const headerRef = useRef(null);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  const isSticky = (e) => {
    const header = document.querySelector(".js-header");
    const scrollTop = window.scrollY;

    scrollTop >= 100
      ? header.classList.add("is-fixed")
      : header.classList.remove("is-fixed");
    scrollTop >= 120
      ? header.classList.add("is-small")
      : header.classList.remove("is-small");
  };

  const menuLeft = useRef(null);
  const btnToggle = useRef(null);

  const menuToggle = () => {
    menuLeft.current.classList.toggle("active");
    btnToggle.current.classList.toggle("active");
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleOnClick = (index) => {
    setActiveIndex(index);
  };

  function truncateEthereumAddress(address) {
    const truncatedAddress =
      address.substring(0, 6) + "..." + address.substring(address.length - 4);
    return truncatedAddress;
  }

  return (
    <div>
      <header id="header_main" className="header_1 js-header" ref={headerRef}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div
                className="mobile-button"
                ref={btnToggle}
                onClick={menuToggle}
              >
                <span></span>
              </div>
              <div id="site-header-inner" className="flex">
                <div id="site-logo" className="clearfix">
                  <div id="site-logo-inner">
                    <Link to="/" rel="home" className="main-logo">
                      <img
                        id="logo_header"
                        className="logo-dark"
                        src="/assets/logo.webp"
                        alt="nft-gaming"
                      />
                      <img
                        id="logo_header"
                        className="logo-light"
                        src="/assets/logo.webp"
                        alt="nft-gaming"
                      />
                    </Link>
                  </div>
                </div>

                <nav id="main-nav" className="main-nav" ref={menuLeft}>
                  <ul id="menu-primary-menu" className="menu">
                    {menus.map((data, index) => (
                      <li
                        key={index}
                        onClick={() => handleOnClick(index)}
                        className={`menu-item  ${
                          activeIndex === index ? "active" : ""
                        } `}
                      >
                        <a href={data.links ? data.links : "#"} target="_blank">
                          {data.name}
                        </a>
                        {data.namesub && (
                          <ul className="sub-menu">
                            {data.namesub.map((submenu, index) => (
                              <li
                                key={index}
                                className={
                                  pathname === submenu.links
                                    ? "menu-item current-item"
                                    : "menu-item"
                                }
                              >
                                <Link to={submenu.links}>{submenu.sub}</Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </nav>
                <ConnectKitButton.Custom>
                  {({
                    isConnected,
                    isConnecting,
                    show,
                    hide,
                    address,
                    ensName,
                    chain,
                  }) => {
                    return (
                      <>
                        <div className="button-connect-wallet">
                          <button
                            className="sc-button wallet  style-2"
                            onClick={show}
                          >
                            <img src={icon} alt="icon" />{" "}
                            <span>
                              {isConnected
                                ? truncateEthereumAddress(address)
                                : "Connect Wallet"}
                            </span>
                          </button>
                        </div>
                      </>
                    );
                  }}
                </ConnectKitButton.Custom>

                <DarkMode />
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
