import { useEffect } from "react";
import { useState } from "react";
import { Alert, Form, InputGroup, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { erc20Abi } from "viem";
import { useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import { addDecimal, removeDecimal } from "../myfolder/crypto/helpers";
import { info } from "../myfolder/crypto/info";
import { stakingAbi } from "../myfolder/crypto/stakingAbi";

function StakeModal({
  show,
  setShow,
  balanceInfo,
  approvedAmount,
  warningModalContent,
}) {
  const handleClose = () => setShow(false);
  const [amount, setamount] = useState(0);
  //const { approvedBalance, approve, stake } = warningModalContent;
  const [txHappening, settxHappening] = useState(false);
  const [approvedNeeded, setApprovedNeeded] = useState(false);
  const [balanceEnough, setbalanceEnough] = useState(true);
  const {
    data: approveData,
    writeContract: approve,
    error: approveTxError,
  } = useWriteContract();
  const {
    data: stakeData,
    writeContract: stake,
    error: stakeTxError,
  } = useWriteContract();

  const {
    isLoading: isApproving,
    isSuccess: isApproved,
    error: approveError,
  } = useWaitForTransactionReceipt({
    hash: approveData,
  });

  useEffect(() => {
    console.log(stakeTxError, approveTxError);
  }, [stakeTxError, approveTxError]);

  useEffect(() => {
    if (amount && Number(approvedAmount?.toString()) >= 0) {
      if (
        Number(addDecimal(amount.toString(), info.stakingTokenDecimal, 0)) >
        Number(approvedAmount?.toString())
      ) {
        setApprovedNeeded(true);
      }
    }
  }, [amount, approvedAmount]);

  useEffect(() => {
    console.log(
      Number(addDecimal(amount.toString(), info.stakingTokenDecimal)) >
        Number(balanceInfo?.toString()),
      Number(addDecimal(amount.toString(), info.stakingTokenDecimal)),
      Number(balanceInfo?.toString())
    );
    if (amount && Number(balanceInfo?.toString()) >= 0) {
      if (
        Number(addDecimal(amount.toString(), info.stakingTokenDecimal)) <
        Number(balanceInfo?.toString())
      ) {
        setbalanceEnough(true);
      } else {
        setbalanceEnough(false);
      }
    }
  }, [amount, balanceInfo]);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="mt-3 mb-3">
            <h4>Stake Your {info.stakingTokenSymbol}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mt-5 mb-5">
          <h4>
            You Have{" "}
            {balanceInfo &&
              removeDecimal(
                balanceInfo?.toString(),
                info.stakingTokenDecimal,
                4
              ) +
                " " +
                info.stakingTokenSymbol}
          </h4>
          <InputGroup className="mb-3 mt-5" size="lg">
            <Form.Control
              placeholder="Amount to stake"
              aria-label="Amount to stake"
              aria-describedby="basic-addon2"
              type="number"
              value={amount}
              onChange={(e) => setamount(e.target.value)}
            />
            <InputGroup.Text id="basic-addon2">
              {info.stakingTokenSymbol}
            </InputGroup.Text>
          </InputGroup>
          {approvedNeeded && balanceEnough && (
            <Alert variant="warning">
              You need to approve your tokens on staking contract
            </Alert>
          )}
          {!balanceEnough && (
            <Alert variant="danger">You don't have enough balance.</Alert>
          )}
          {isApproving && <Spinner></Spinner>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              const run = async () => {
                approvedNeeded
                  ? await approve({
                      address: info.stakingTokenAddress,
                      abi: erc20Abi,
                      functionName: "approve",
                      args: [
                        info.contractAddress,
                        addDecimal(amount, info.stakingTokenDecimal),
                      ],
                    })
                  : await stake({
                      address: info.contractAddress,
                      abi: stakingAbi,
                      functionName: "stake",
                      args: [addDecimal(amount, info.stakingTokenDecimal)],
                    });
              };
              run();
            }}
            disabled={!balanceEnough}
          >
            {approvedNeeded ? "Approve" : "Stake"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default StakeModal;
