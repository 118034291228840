import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import imgsun from "../../assets/images/icon/sun.png";

const DarkMode = () => {
  let clickedClass = "clicked";
  const body = document.body;
  const lightTheme = "light";
  const darkTheme = "is_dark";
  let theme;

  const changeTheme = (theme) => {
    const html = document.getElementsByTagName("html")[0]; // İlk (ve tek) öğeye erişim
    if (theme === "light") {
      html.setAttribute("data-bs-theme", "light");
    } else {
      html.setAttribute("data-bs-theme", "dark");
    }
  };

  if (localStorage) {
    theme = localStorage.getItem("theme");
  }
  if (theme === lightTheme || theme === darkTheme) {
    body.classList.add(theme);
    changeTheme(theme);
  } else {
    body.classList.add(lightTheme);
    changeTheme("light");
  }

  const switchTheme = (e) => {
    if (theme === darkTheme) {
      body.classList.replace(darkTheme, lightTheme);
      e.target.classList.remove(clickedClass);
      localStorage.setItem("theme", "light");
      theme = lightTheme;
      changeTheme("light");
    } else {
      body.classList.replace(lightTheme, darkTheme);
      e.target.classList.add(clickedClass);
      localStorage.setItem("theme", "is_dark");
      theme = darkTheme;
      changeTheme("is_dark");
    }
  };
  return (
    <div className="mode_switcher">
      <Link to="#" onClick={(e) => switchTheme(e)}>
        <img src={imgsun} alt="" />
      </Link>
    </div>
  );
};

export default DarkMode;
