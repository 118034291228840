import React, { useEffect, useState } from "react";
import Slider01 from "../components/slider/Slider01";
import img1 from "../assets/images/slider/joops.webp";
import { useAccount, useReadContract, useWriteContract } from "wagmi";
import { stakingAbi } from "../myfolder/crypto/stakingAbi";
import { info } from "../myfolder/crypto/info";
import { removeDecimal } from "../myfolder/crypto/helpers";
import { erc20Abi } from "viem";
import WarningModal from "../components/WarningModal";
import StakeModal from "../components/StakeModal";

const dataSlider = [
  {
    title: "Maximize Your Crypto Earnings with Our Staking Platform",
    description: "Secure, Reliable, and High-Yield Staking Opportunities",
    img: img1,
  },
];

const Home01 = () => {
  const { address, isConnected } = useAccount();
  const [apy, setapy] = useState(null);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showStakeModal, setShowStakeModal] = useState(false);

  const {
    data: claimData,
    writeContract: claim,
    error: claimTxError,
  } = useWriteContract();
  const {
    data: withdrawData,
    writeContract: withdraw,
    error: withdrawTxError,
  } = useWriteContract();

  useEffect(() => {
    console.log(withdrawTxError, claimTxError);
  }, [withdrawTxError, claimTxError]);

  const [warningModalContent, setWarningModalContent] = useState({
    modalTitle: "",
    modalHeading: "",
    modalBody: "",
    buttonName: "",
    onClick: "",
  });

  const { data: totalStaked, error: stakedError } = useReadContract({
    abi: stakingAbi,
    address: info.contractAddress,
    functionName: "stakingtokenBalance",
    watch: true,
  });

  const { data: rewardBalance, error: rewardBalanceError } = useReadContract({
    abi: stakingAbi,
    address: info.contractAddress,
    functionName: "getRewardTokenBalance",
    watch: true,
  });

  const { data: rewardRatio, error: ratioError } = useReadContract({
    abi: stakingAbi,
    address: info.contractAddress,
    functionName: "getRewardRatio",
  });

  const { data: timeUnit, error: timeError } = useReadContract({
    abi: stakingAbi,
    address: info.contractAddress,
    functionName: "getTimeUnit",
  });

  const { data: stakeInfo, error: infoError } = useReadContract({
    abi: stakingAbi,
    address: info.contractAddress,
    functionName: "getStakeInfo",
    args: [address && address],
    watch: true,
  });

  const { data: balanceInfo, error: balanceError } = useReadContract({
    abi: erc20Abi,
    address: info.stakingTokenAddress,
    functionName: "balanceOf",
    args: [address && address],
    watch: true,
  });

  const { data: totalSupplyReward, error: rewardTotalSupplyError } =
    useReadContract({
      abi: erc20Abi,
      address: info.rewardTokenAddress,
      functionName: "totalSupply",
      watch: true,
    });

  const { data: approvedAmount, error: approvedAmountError } = useReadContract({
    abi: erc20Abi,
    address: info.stakingTokenAddress,
    functionName: "allowance",
    args: [address && address, info.contractAddress],
    watch: true,
  });

  useEffect(() => {
    if (timeUnit && rewardRatio) {
      const percent =
        Number(rewardRatio[0]?.toString()) / Number(rewardRatio[1]?.toString());
      const timeLine = 31556926 / Number(timeUnit?.toString());
      const apypercent = percent * timeLine;
      setapy(apypercent.toFixed(2));
    }
  }, [timeUnit, rewardRatio]);

  const withdrawTokens = async () => {
    await withdraw({
      address: info.contractAddress,
      abi: stakingAbi,
      functionName: "withdraw",
      args: [stakeInfo[0]?.toString()],
    });
  };

  const claimRewards = async () => {
    await claim({
      address: info.contractAddress,
      abi: stakingAbi,
      functionName: "claimRewards",
    });
  };

  return (
    <div className="home-1">
      <Slider01 data={dataSlider} />

      <section className="tf-trendy-collections tf-section">
        <div className="container">
          <div className="row">
            <h4 style={{ lineHeight: "normal" }}>
              1. Connect Your Wallet: Securely connect your crypto wallet to our
              platform.
            </h4>
            <h4 className="mt-4" style={{ lineHeight: "normal" }}>
              2. Stake Your Crypto:Choose the cryptocurrency (JOOPS) you want to
              stake from your wallet.
            </h4>
            <h4 className="mt-4" style={{ lineHeight: "normal" }}>
              3. Deposit Assets:Deposit your assets into the chosen staking pool
              to start earning rewards.
            </h4>
            <h4 className="mt-4" style={{ lineHeight: "normal" }}>
              4. Earn Rewards:Watch your crypto grow as you receive regular
              staking rewards.
            </h4>
            <h4 className="mt-4" style={{ lineHeight: "normal" }}>
              5. Minimum Staking Period:A minimum staking period of 30 days is
              required to qualify for rewards, which will be awarded after this
              period.
            </h4>
            <h4 className="mt-4" style={{ lineHeight: "normal" }}>
              6. Early Withdrawal Policy:If staked tokens are withdrawn before
              the 30-day period, any accumulated rewards will be forfeited.
            </h4>

            <div className="trendy" style={{ width: "100%" }}>
              <div className="col-lg-4 col-md-4 col-12 px-3">
                <div
                  className="sc-product-item style-2"
                  style={{ border: "14px solid #1FDF67" }}
                >
                  <div className="product-content">
                    <h4 className="title mt-5 text-center">You Staked</h4>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                      }}
                    >
                      <h5 className="title mt-3 text-center">
                        {address && isConnected && stakeInfo?.length > 0
                          ? removeDecimal(
                              stakeInfo[0],
                              info.stakingTokenDecimal,
                              4
                            ) +
                            " " +
                            info.stakingTokenSymbol
                          : "Connect To See"}
                      </h5>
                      {address && isConnected && stakeInfo?.length > 0 && (
                        <button
                          className="btn btn-danger mb-2"
                          style={{ height: "30px", width: "60px" }}
                          onClick={() => {
                            setWarningModalContent({
                              modalTitle: "Withdraw your Tokens",
                              modalHeading:
                                "Are you sure you want to withdraw your balance?",
                              modalBody: `You are about to withdraw your tokens. Your balance is ${removeDecimal(
                                stakeInfo[0],
                                info.stakingTokenDecimal,
                                4
                              )} `,
                              buttonName: "Withdraw",
                              onClick: withdrawTokens,
                            });
                            setShowWarningModal(true);
                          }}
                        >
                          Withdraw
                        </button>
                      )}
                    </div>

                    <hr />
                    <h4 className="title mt-5 text-center">Your Balance</h4>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                      }}
                    >
                      {" "}
                      <h5 className="title mt-3 text-center">
                        {address &&
                        isConnected &&
                        Number(balanceInfo?.toString()) >= 0
                          ? removeDecimal(
                              balanceInfo,
                              info.stakingTokenDecimal,
                              4
                            ) +
                            " " +
                            info.stakingTokenSymbol
                          : "Connect To See"}{" "}
                      </h5>
                      {address && isConnected && (
                        <button
                          className="btn btn-success mb-2"
                          style={{ height: "30px", width: "60px" }}
                          onClick={() => setShowStakeModal(true)}
                        >
                          Stake
                        </button>
                      )}
                    </div>

                    <hr />
                    <h4 className="title mt-5 text-center">Your Reward</h4>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                      }}
                    >
                      {" "}
                      <h5 className="title mt-3 text-center">
                        {address && isConnected && stakeInfo?.length > 0
                          ? removeDecimal(
                              stakeInfo[1],
                              info.rewardTokenDecimal,
                              4
                            ) +
                            " " +
                            info.rewardTokenSymbol
                          : "Connect To See"}
                      </h5>
                      {address && isConnected && (
                        <button
                          className="btn btn-warning mb-2"
                          style={{ height: "30px", width: "60px" }}
                          onClick={() => {
                            setWarningModalContent({
                              modalTitle: "Claim Your Reward",
                              modalHeading:
                                "Are you sure you want to claim your reward?",
                              modalBody: `You are about to claim your reward tokens. Your reward balance is ${removeDecimal(
                                stakeInfo[1],
                                info.rewardTokenDecimal,
                                4
                              )} `,
                              buttonName: "Claim",
                              onClick: claimRewards,
                            });
                            setShowWarningModal(true);
                          }}
                        >
                          Claim
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-12 px-1">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div
                      className="sc-product-item style-2"
                      style={{ border: "14px solid #ED45A0" }}
                    >
                      <div className="product-content">
                        <h4 className="title mt-5 text-center">Total Staked</h4>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                          className="mb-5"
                        >
                          <h5>
                            {removeDecimal(
                              totalStaked,
                              info.stakingTokenDecimal,
                              4
                            ) +
                              " " +
                              info.stakingTokenSymbol}
                          </h5>
                          <i className="" />
                        </div>
                        <h5 className="mb-5">{info.stakingTokenSymbol}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div
                      className="sc-product-item style-2"
                      style={{ border: "14px solid #1FDF67" }}
                    >
                      <div className="product-content">
                        <h4 className="title mt-5 text-center">Reward Rate</h4>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                          className="mb-5"
                        >
                          <h5>{Number(apy) * 100}%</h5>
                          <i className="" />
                        </div>
                        <h5 className="mb-5">APY</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-12">
                    <div
                      className="sc-product-item style-2"
                      style={{
                        minHeight: "200px",
                        border: "14px solid #ED45A0",
                      }}
                    >
                      <div className="product-content">
                        <h5 className="title mt-4 text-center">
                          {info.rewardTokenSymbol} Stats{" "}
                        </h5>
                        <div className="row">
                          <div className="col-md-6">
                            <h4 className="title mt-2 text-center">
                              {info.rewardTokenSymbol} Total Supply
                            </h4>
                            <h5 className="title mt-3 text-center">
                              {removeDecimal(
                                totalSupplyReward,
                                info.rewardTokenDecimal,
                                4
                              ) +
                                " " +
                                info.rewardTokenSymbol}
                            </h5>
                          </div>
                          <div className="col-md-6">
                            <h4 className="title mt-2 text-center">
                              Rewards in Pool
                            </h4>
                            <h5 className="title mt-3 text-center">
                              {removeDecimal(
                                rewardBalance,
                                info.rewardTokenDecimal,
                                2
                              ) +
                                " " +
                                info.rewardTokenSymbol}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {address && isConnected && (
                  <WarningModal
                    warningModalContent={warningModalContent}
                    show={showWarningModal}
                    setShow={setShowWarningModal}
                  />
                )}
                {address && isConnected && (
                  <StakeModal
                    balanceInfo={balanceInfo}
                    show={showStakeModal}
                    setShow={setShowStakeModal}
                    approvedAmount={approvedAmount}
                  />
                )}
              </div>
              <h4 className="mt-3" style={{ lineHeight: "normal" }}>
                Please be advised that staking rewards earned through our
                platform will not be visible until the minimum 30-day staking
                period has concluded. This policy ensures the integrity and
                proper calculation of rewards for all users.
              </h4>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home01;
